import FormPage from '@/components/FormPage'
import security from '@/security'
import { createGetParams, createPostParams } from '@/rest'
import {
  getRm,
  getNumeralFromRm,
} from '@/utils'

const formTitle = 'Transfer e-recharge'

export default {
  extends: FormPage,
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Dealer Kits', disabled: true, to: '/dealer_kits',
        },
        {
          text: 'E-Recharge', disabled: false, to: '/ecash_kits_demoted',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
      title: formTitle,
      // Form steps
      transferDetails: {
        fields: {
          ecash: {
            name: 'E-recharge balance',
          },
          dealer: {
            component: () => import(/* webpackChunkName: "dealerField" */ '@/components/DealerField'),
            name: 'To dealer Id',
            props: {
              persistentHint: true,
              label: 'Enter Dealer Id',
              noSelf: true,
              claimer: security.me.claimer(),
              filterClaimer: true,
              required: true,
              rules: [val => !!val || 'Dealer Id required!'],
            },
          },
          amount: {
            component: () => import(/* webpackChunkName: "amountField" */ '@/components/AmountField'),
            name: 'Transfer amount',
            props: {
              label: 'Enter transfer amount',
              required: true,
            },
          },
        },
        title: 'E-recharge transfer details',
      },
      submission: {
        fields: {
          dealer: {
            name: 'To dealer Id',
          },
          amount: {
            emphasize: true,
            name: 'Transfer amount',
          },
        },
        submit: false,
        errorMessage: null,
        status: null,
        subTitle: 'Check before submit',
        title: 'Submit',
      },
    }
  },
  computed: {
    formSteps: function () {
      return {
        transferDetails: this.transferDetails,
        submission: this.submission,
      }
    },
  },
  mounted: function () {
    const params = createGetParams({
      erecharge: true,
    })
    this.$rest.get('getDealerResources.php', params).then(response => {
      this.transferDetails.fields.ecash.value = getRm(response.data.erecharge)
    })
    this.transferDetails.fields.amount.props.rules.push(val => this.validateAmount(val))
  },
  watch: {
    'transferDetails.fields.dealer.value': function (val) {
      this.submission.fields.dealer.value = val
    },
    'transferDetails.fields.amount.value': function (val) {
      this.submission.fields.amount.value = getRm(val)
    },
  },
  methods: {
    validateAmount: function (val) {
      const regex = / /gi
      const value = val.replace(regex, '')
      if (Number(value) <= 0) return 'Transfer amount required!'
      if (Number(value) > getNumeralFromRm(this.transferDetails.fields.ecash.value)) return 'Insufficient e-recharge!'
      return true
    },
    // Override
    async submit () {
      const params = createPostParams({
        action: 'transferErecharge',
        payload: {
          target: this.transferDetails.fields.dealer.value,
          amount: Number(this.transferDetails.fields.amount.value).toFixed(2),
        },
      })

      try {
        const response = await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.alertText = 'Your request to transfer e-recharge was successfully submitted.'
          this.status = this.submission.status = 'success'
          this.submission.submit = false
          this.isImpersonated() && this.showSubmissionResponse(response.data)
        })
      } catch (e) {
        this.$nextTick(() => {
          this.status = this.submission.status = 'error'
          this.submission.errorMessage = e.message + ': ' + e.response.data
          this.submission.submit = false
        })
      }
    },
  },
}
